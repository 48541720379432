import React, { useEffect, useState } from 'react'
import '../components/assets/css/userList.css'
import OrderDataTable from 'react-data-table-component'
import SearchBar from './SearchBar';
import useClipboard from "react-use-clipboard";
import { CSVLink } from "react-csv";
import AddUserModal from './modals/AddUserModal';

function UserListCom2() {
    const [modalShown, toggleModal] = useState(false);
    const [load,setload]=useState(false)
    const[data,setData]=useState([])
useEffect(()=>{
    setload(true)
    fetch('https://vidmabackend.scoopsolutions.us/getAllEmployees',{
        method:"GET",
        headers:{'content-type':'application/json'},
      }).then(res=>{
        return res.json()
      }).then(ress=>{
        setload(false)
       var temp
       temp=ress.data
       setData([...temp])
       console.log(data)
      })
},[])
    // const Data = [
    //     {
    //         Name: "Emma David",
    //         Email: "emmadavid@logochemist.com",
    //         Rights: "Project Manager",
    //         Status: "Active",
    //         Register_Date_time: "16-Aug-22, 01:56 PM",
    //         Last_login: "06-Apr-23, 03:11 AM",
    //     },
    //     {
    //         Name: "Emma David",
    //         Email: "emmadavid@logochemist.com",
    //         Rights: "Project Manager",
    //         Status: "Active",
    //         Register_Date_time: "16-Aug-22, 01:56 PM",
    //         Last_login: "06-Apr-23, 03:11 AM",
    //     },
    //     {
    //         Name: "Emma David",
    //         Email: "emmadavid@logochemist.com",
    //         Rights: "Project Manager",
    //         Status: "Active",
    //         Register_Date_time: "16-Aug-22, 01:56 PM",
    //         Last_login: "06-Apr-23, 03:11 AM",
    //     },
    //     {
    //         Name: "Emma David",
    //         Email: "emmadavid@logochemist.com",
    //         Rights: "Project Manager",
    //         Status: "Active",
    //         Register_Date_time: "16-Aug-22, 01:56 PM",
    //         Last_login: "06-Apr-23, 03:11 AM",
    //     },
    //     {
    //         Name: "Emma David",
    //         Email: "emmadavid@logochemist.com",
    //         Rights: "Project Manager",
    //         Status: "Active",
    //         Register_Date_time: "16-Aug-22, 01:56 PM",
    //         Last_login: "06-Apr-23, 03:11 AM",
    //     },
    //     {
    //         Name: "Emma David",
    //         Email: "emmadavid@logochemist.com",
    //         Rights: "Project Manager",
    //         Status: "Active",
    //         Register_Date_time: "16-Aug-22, 01:56 PM",
    //         Last_login: "06-Apr-23, 03:11 AM",
    //     },
    // ];

    const [isCopied, setCopied] = useClipboard(JSON.stringify(data));
    const countPerPage = 5;

    const columns = [
        {
            name: "R.No",
            cell: (row, index) => index + 1
        },
        {
            name: "Name",
            selector: (row) => <span>{row.Emp_Name}</span>

        },
        {
            name: "Email",
            selector: (row) => <span>{row.Email}</span>
        },
        {
            name: "Number",
            selector: (row) => <span>{row.Phone_Number}</span>
        },
        {
            name: "Designation",
            selector: (row) => <span>{row.Designation}</span>
        },
        {
            name: "City",
            selector: (row) => <span>{row.City}</span>
        },
        {
            name: "State",
            selector: (row) => <span>{row.State}</span>
        },
        // {
        //     name : "Actions",
        //     cell : (row) => <div className='actionsBtns'>
        //         <button className='upadteBtn'>Update</button>
        //         <button className='deleteBtn'>Delete</button>
        //         <button className='updatePwdBtn'>Update Pwd!</button>
        //     </div>
        // }
    ]
    const addUser = () => {
        toggleModal(!modalShown);
    }
    return (
        <>
            <div className="userListBox mt-5 mb-5">
                
                <div className="innerUserListBox">
                    {/* <div className="btnBox">
                    <button className="addUserBtn" onClick={addUser}>Add User</button> */}
                        {/* <button onClick={setCopied}> Copy</button>
                        <CSVLink data={Data} filename={"LeadFile.csv"}>CSV</CSVLink>
                        <button>Excel</button>
                        <button>PDF</button>
                        <button>Print</button> */}
                    {/* </div> */}
                    {/* <SearchBar /> */}
                </div>
                <OrderDataTable
                    columns={columns}
                    data={data}
                    highlightOnHover
                    // pagination
                    // paginationServer
                    // paginationPerPage={countPerPage}
                    // paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                />
            </div>
            <AddUserModal close={() => {toggleModal(false)}}
            {...{modalShown,}}/>
        </>
    )
}

export default UserListCom2