import React from "react";
import { Form } from "react-router-dom";
import LoginImage from "../assets/images/login.svg";
import "../components/assets/css/login.css";
import { useState } from "react";

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const LoginSubmit = () => {
    if (email == "") {
      alert("enter email")
    }
    else if (password == "") {
      alert("enter password")
    }
    else {
      fetch('https://vidmabackend.scoopsolutions.us/AdminLogin', {
        method: "POST",
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          "email": email,
          "password": password
        })
      }).then(res => {
        return res.json()
      }).then(ress => {
        alert(ress.message)
        if (ress.message == 'successfully login') {
          localStorage.setItem('token', ress.token)
          window.location.href = '/Dashboard'
        }

      })
    }
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="">
            <h1>We are Vidma</h1>
            <h6>Welcome back, please login to your account</h6>
            <div className="formBox">
              <div class="form-group mt-4">
                <label>*User Name</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Username"
                />
              </div>
              <div class="form-group mt-4">
                <label>*Password</label>
                <input

                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  class="form-control"
                  id=""
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      LoginSubmit()
                    }
                  }}
                  placeholder="Password"
                />
              </div>
              <button type="submit" onClick={() => LoginSubmit()} className="btn btn-primary signInBtn">
                Sign In
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="login_image">
            <img src={LoginImage} alt="login" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
