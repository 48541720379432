import React, { useState } from 'react'
import './assets/css/topBar.css'
import { AiOutlineAlignRight as Bar_ico } from "react-icons/ai";
import SearchBar from './SearchBar';
import MassageBox from './MassageBox';
import NotifyBox from './NotifyBox';
import UserProfile from './UserProfile';
import ResponsiveDrawer from './ResponsiveDrawer';


function TopBar(props) {

  return (
    <>
      <div className="topBarBox">
        <span className='barIco d-md-inline d-lg-inline d-none'>
          <Bar_ico
            onClick={props.hideShowMenuClick}
          />
        </span>
        <span className='barIco-res d-md-none d-lg-none d-inline-block'>
          <ResponsiveDrawer />
        </span>
        <div className="leftAreaBoxTopBar">
          {/* <SearchBar />
            <MassageBox />
            <NotifyBox/> */}
          <UserProfile />
        </div>
      </div>
    </>
  )
}

export default TopBar