import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { RiDashboardLine as Dashboard_ico } from "react-icons/ri";
import { SlBasket as Order_ico } from "react-icons/sl";
import { BsFillChatSquareTextFill as Chat_ico } from "react-icons/bs";
import { FaClipboardList as CustomerList_ico } from "react-icons/fa";
import { RiErrorWarningLine as Warning_ico } from "react-icons/ri";
import { FiUserMinus as UserList_ico } from "react-icons/fi";
import { AiOutlineBuild as Agent_ico } from "react-icons/ai";
import { TbSteam as Customer_leads_ico } from "react-icons/tb";
import { BsBodyText as Customer_quotation_ico } from "react-icons/bs";
import { RiProfileLine as Profile_ico } from "react-icons/ri";
import { CiInboxIn as Inbox_ico } from "react-icons/ci";
import { SiCivicrm as Crm_logs_ico } from "react-icons/si";
import { Link, useNavigate, useParams } from 'react-router-dom'
const ResponsiveDrawer = () => {
    const drawerWidth = 240;

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${drawerWidth}px`,
            ...(open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            }),
        }),
    );

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="container-fluid d-md-none d-lg-none d-block">
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline /><IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={open}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <List>
                            <div className="menuBox" id='menuli'>
                                <ul className='d-flex gap-5 flex-column align-items-start '>
                                    <li className='mt-3 '>
                                        <span className="pe-2">
                                            <Dashboard_ico />
                                        </span>
                                        <Link className='text-dark fw-600' to="/" id='hideTxt'>Dashboard</Link>
                                    </li>
                                    <li>
                                        <span className="pe-2">
                                            <UserList_ico />
                                        </span>
                                        <Link className='text-dark fw-600' to="/UsersList" id='hideTxt'>Employers</Link>
                                    </li>
                                    <li>
                                        <span className="pe-2">
                                            <UserList_ico />
                                        </span>
                                        <Link className='text-dark fw-600' to="/UsersList2" id='hideTxt'>Employees</Link>
                                    </li>
                                    <li>
                                        <span className="pe-2">
                                            <CustomerList_ico />
                                        </span>
                                        <Link className='text-dark fw-600' to="/CustomersList" id='hideTxt'>Contacts</Link>
                                    </li>
                                    <li>
                                        <span className="pe-2">
                                            <CustomerList_ico />
                                        </span>

                                        <Link className='text-dark fw-600' to="/services" id='hideTxt'>Services</Link>
                                    </li>
                                </ul>
                            </div>
                        </List>
                        <Divider />
                    </Drawer>
                    <Main open={open}>
                        {/* <DrawerHeader /> */}
                    </Main>
                </Box>
            </div>

        </>
    )
}

export default ResponsiveDrawer