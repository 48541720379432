import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from '../pages/Dashboard'
// CSS ==========================
import '../assets/css/main.css'
// BOOTSTRAP ====================
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import AllOrders from '../pages/AllOrders';
import CustomersList from '../pages/CustomersList';
import DisputeOrder from '../pages/DisputeOrder';
import UsersList from '../pages/UsersList';
import Login from '../components/Login';
import Register from '../components/Register';
import UserStatistics from '../pages/UserStatistics';
import UsersList2 from '../pages/UsersList2';
import ShowServices from '../pages/ShowServices';
function routing() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== "" ? <Dashboard /> : <Login />} />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="CustomersList" element={<CustomersList />} />
          <Route path="DisputeOrder" element={<DisputeOrder />} />
          <Route path="UsersList" element={<UsersList />} />
          <Route path="UsersList2" element={<UsersList2 />} />
          <Route path='AllOrders' element={<AllOrders />} />
          <Route path='UserStatistics' element={<UserStatistics />} />
          <Route path='services' element={<ShowServices />} />
        </Routes>
      </Router>
    </>
  )
}

export default routing