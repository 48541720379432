import React,{useState,useEffect} from 'react'
import '../components/assets/css/customersList.css'
import OrderDataTable from 'react-data-table-component'
import SearchBar from './SearchBar';
import useClipboard from "react-use-clipboard";
import { CSVLink } from "react-csv";

function CustomersListCom() {

  

    // const [isCopied, setCopied] = useClipboard(JSON.stringify(Data));
    // const countPerPage = 5;



    const [load,setload]=useState(false)
    const[data,setData]=useState([])
useEffect(()=>{
    setload(true)
    fetch('https://vidmabackend.scoopsolutions.us/getContactAdmin',{
        method:"GET",
        headers:{'content-type':'application/json'},
      }).then(res=>{
        return res.json()
      }).then(ress=>{
        setload(false)
       var temp
       temp=ress.data
       setData([...temp])
       console.log(data)
      })
},[])

const columns = [
    {
        name: "R.No",
        cell: (row, index) => index + 1
    },
    {
        name: "Employer Name",
        selector: (row) => <span>{row.Emp_Name}</span>

    },
    {
        name: "Email",
        selector: (row) => <span>{row.Email}</span>
    },
    {
        name: "Phone Number",
        selector: (row) => <div>{row.Phone_Number}</div>
    },
    {
        name: "Designation",
        selector: (row) => <div>{row.Designation}</div>
    },
    {
        name: "Subject",
        selector: (row) => <div>{row.subject}</div>
    },
    {
        name: "Message",
        selector: (row) => <div>{row.message}</div>
    },
    {
        name: "Created At",
        selector: (row) => <div>{row.created_at.split(' ')[0]}</div>
    }
]
    return (
        <>
            <div className="customersList mt-5">
                {/* <div className="innerCusListBox">
                    <div className="btnBox">
                        <button onClick={setCopied}> Copy</button>
                        <CSVLink data={Data} filename={"LeadFile.csv"}>CSV</CSVLink>
                        <button>Excel</button>
                        <button>PDF</button>
                        <button>Print</button>
                    </div>
                    <SearchBar />
                </div> */}
                <OrderDataTable
                    columns={columns}
                    data={data}
                    highlightOnHover
                />
            </div>
        </>
    )
}

export default CustomersListCom