import React,{useState} from 'react'
import './assets/css/addUserModal.css'


function AddUserModal(props) {
    const[emp_name,setemp_name]=useState('')
    const[designation,setdesignation]=useState('')
    const[phonenumber,setphonenumber]=useState('')
    const[email,setemail]=useState('')
    const[password,setpassword]=useState('')
    const[city,setcity]=useState('')
    const[state,setstate]=useState('')
  
    const CreateEmployer=()=>{
      if(emp_name==""){
        alert("enter name")
      }
      else if(designation==""){
        alert("enter designation")
      }
      else if(phonenumber==""){
        alert("enter number")
      }
      else if(email==""){
        alert("enter email")
      }
      else if(password==""){
        alert("enter password")
      }
      else if(city==""){
        alert("enter city")
      }
      else if(state==""){
        alert("enter state")
      }
      else{
        fetch('https://vidmabackend.scoopsolutions.us/EmployerSignup',{
          method:"POST",
          headers:{'content-type':'application/json'},
          body:JSON.stringify({
            "emp_name": emp_name,
            "designation":designation,
            "phonenumber":phonenumber,
            "email":email,
            "password":password,
            "city":city,
            "state":state
        
        })
        }).then(res=>{
          return res.json()
        }).then(ress=>{
          alert(ress.message)
          if(ress.message=='Successfull'){
            window.location.reload(false)
          }
         
        })
      }
    }
    return props.modalShown ? (
        <>
            <div className="addUserModalBox" >
                <div className="addUserBox">
                 
                    <div>
                        <div className="inneraddUserBox">
                        
                            <div class="form-group">
                            <h4>Add New User!</h4>
                                <label>Name</label>
                                <input
                                onChange={(e)=>setemp_name(e.target.value)}
                                type="text" class="form-control" placeholder="Name here!" required/>
                            </div>
                            {/* <div className="form-group">
                                <label>User Type</label>
                                <select class="form-select">
                                    <option selected>Select a user type</option>
                                    <option value="Fresher">Fresher</option>
                                    <option value="Closer">Closer</option>
                                    <option value="Project Manager">Project Manager</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="inneraddUserBox">
                        <div class="form-group">
                                <label>Email</label>
                                <input 
                                onChange={(e)=>setemail(e.target.value)}
                                type="email" class="form-control" placeholder="Email here!" required/>
                            </div>
                            <div class="form-group">
                                <label>Designation</label>
                                <input 
                                onChange={(e)=>setdesignation(e.target.value)}
                                type="text" class="form-control" placeholder="Designation here!" required/>
                            </div>
                            <div class="form-group">
                                <label>Number</label>
                                <input
                                onChange={(e)=>setphonenumber(e.target.value)}
                                type="number" class="form-control" placeholder="Number here!" required/>
                            </div>
                            <div class="form-group">
                                <label>City</label>
                                <input 
                                onChange={(e)=>setcity(e.target.value)}
                                type="text" class="form-control" placeholder="City here!" required/>
                            </div>
                            <div class="form-group">
                                <label>State</label>
                                <input 
                                onChange={(e)=>setstate(e.target.value)}
                                type="text" class="form-control" placeholder="State here!" required/>
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input 
                                onChange={(e)=>setpassword(e.target.value)}
                                type="password" class="form-control" placeholder="Password here!" required />
                            </div>
                        </div>
                        <div className="addUserBtnBox">
                            <button onClick={()=>CreateEmployer()}>Submit</button>
                            <button onClick={() => {props.close()}}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : null
}



export default AddUserModal