import React, { useState } from 'react'

const AddServiceModal = (props) => {
    const [description, setdescription] = useState("");
    const addDescription = () => {
        fetch(`http://vidmabackend.scoopsolutions.us/CreateService`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({
                "description": description
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.message === 'created') {
                console.log(res.message)
                window.location.reload(true)
            }
            else {
                console.log(res.message);
            }
        })
    }
    return props.modalShown ? (
        <>
            <div className="addUserModalBox" >
                <div className="addUserBox">

                    <div>
                        <div className="inneraddUserBox">

                            <div class="form-group">
                                <h4>Add Service!</h4>
                            </div>
                        </div>
                        <div className="inneraddUserBox">
                            <div class="form-group">
                                <label>Description</label>
                                <input
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            addDescription()
                                        }
                                    }}
                                    onChange={(e) => setdescription(e.target.value)}
                                    type="text" class="form-control" placeholder="Description here!" required />
                            </div>
                        </div>
                        <div className="addUserBtnBox">
                            <button onClick={() => addDescription()} >Submit</button>
                            <button onClick={() => { props.close() }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : null
}

export default AddServiceModal