import React, { useState, useEffect } from 'react'
import '../components/assets/css/userList.css'
import OrderDataTable from 'react-data-table-component'
import AddServiceModal from './modals/AddServiceModal'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};
const Services = () => {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState([]);
    const handleOpen = (id) => {
        setOpen(true)
        setId(id)
    };
    const handleClose = () => setOpen(false);
    const [modalShown, toggleModal] = useState(false);
    const [data, setdata] = useState([]);
    const [update, setUpdate] = useState("");
    const addService = () => {
        toggleModal(!modalShown);
    }
    useEffect(() => {
        fetch(`http://vidmabackend.scoopsolutions.us/getService`, {
            method: "GET",
            headers: { 'content-type': 'application/json' }
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.data.length > 0) {
                let temp = []
                temp = res.data
                setdata([...temp])
                console.log(temp, "ssss")
            }
        })
    }, [])
    const EditServices = () => {
        fetch(`http://vidmabackend.scoopsolutions.us/UpdateService`, {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                "id": id,
                "description": update
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.message === "updated") {
                window.location.reload(true)
                console.log(res.message)
            }
            else {
                console.log(res.message)
            }
        })
    }
    const DeleteServices = (id) => {
        fetch(`http://vidmabackend.scoopsolutions.us/DeleteService`, {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                "id": id,
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.message === "deleted") {
                window.location.reload(true)
                console.log(res.message)
            }
            else {
                console.log(res.message)
            }
        })
    }
    const columns = [
        {
            name: "R.No",
            cell: (row, index) => index + 1
        },
        {
            name: "description",
            selector: (row) => <span>{row.description}</span>

        },
        {
            name: "Delete",
            cell: (row) => <div className='actionsBtns'>
                <button onClick={() => DeleteServices(row.id)} className='deleteBtn'>Delete</button>
            </div>
        },
        {
            name: "Update",
            cell: (row) => <div className='actionsBtns'>
                <button onClick={() => handleOpen(row.id)} className='upadteBtn'>Update</button>
            </div>
        }
    ]
    return (
        <>
            <div className="userListBox mt-5 mb-5">
                <div className="innerUserListBox">
                    <div className="btnBox">
                        <button onClick={() => addService()} className="addUserBtn" >Add Services</button>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Update Service!</h4>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div class="form-group">
                                            <label>Description</label>
                                            <input
                                                onChange={(e) => setUpdate(e.target.value)}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        EditServices();
                                                    }
                                                }}
                                                type="text" value={id.description} class="form-control" placeholder="Description here!" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <div className="addUserBtnBox d-flex align-items-center justify-content-between">
                                            <button onClick={() => EditServices()}>Update</button>
                                            <button onClick={() => handleClose()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
                <OrderDataTable
                    columns={columns}
                    data={data}
                    highlightOnHover
                />
            </div>
            <AddServiceModal close={() => { toggleModal(false) }}
                {...{ modalShown, }} />

        </>
    )
}

export default Services